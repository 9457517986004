import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import './stepper-horizontal.css'

const styles = theme => ({
    root: {
        width: '90%',
    },
    button: {
        marginTop: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    actionsContainer: {
        marginBottom: theme.spacing(2),
    },
    resetContainer: {
        padding: theme.spacing(3),
    },
});

class VerticalLinearStepper extends React.Component {

    render() {
        let { steps, currentStep } = this.props;
        if (!currentStep) currentStep = 0
        return (
            <div class="h-container">
                { steps ? steps.map((step, iStep) => {
                    let className = "h-step"
                    if (iStep < currentStep) {
                        className = `${className} completed`
                    } else if (iStep === currentStep) {
                        className = `${className} active`
                    }

                    let title, description
                    if (typeof step === 'string') {
                        title = step
                        description = false
                    }

                    if (typeof step === 'object') {
                        title = step.title
                        description = step.description
                    }

                    return (
                        <div class={className}>
                            <div class="h-stepper">
                                <div class="circle"></div>
                            </div>

                            { !(iStep === steps.length - 1) && <div className="h-line h-right" /> }
                            { !!iStep && <div className="h-line h-left"/> }

                            <div class="h-content">
                                <div style={{ fontWeight: description ? 'bold': 'normal' }}>{ title }</div>
                                { description && <div>{description}</div>}
                            </div>
                        </div>
                    )
                }): null }
            </div>

        );
    }
}

VerticalLinearStepper.propTypes = {
    steps: PropTypes.array,
    currentStep: PropTypes.number
};

export default withStyles(styles)(VerticalLinearStepper);