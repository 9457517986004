import React, { Component } from "react";
import {createTheme, MuiThemeProvider, withStyles} from '@material-ui/core/styles';
import ThankUBackgound from '../../Icons/Thankubackground'

import LocalStorage from '../../utils/services/storage';
import CheckIcon from '../../Icons/CheckIcon'
import PropTypes from "prop-types";
import WaitingView from './WaitingView'
import { translate } from 'react-admin'
import {compose} from "recompose";
import {connect} from "react-redux";
import {lightTheme} from "../../layout/themes";

const styles = theme => ({
    container: {
        // paddingLeft: 75,
        backgroundColor: '#ffffff',
        position: 'relative',
        height: '100%'
    },
    centerContent: {
        zIndex: 5,
        position: 'absolute',
        height: 463,
        width: 728.7,
        top: 0,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        [theme.breakpoints.down('xs')]: {
            width: 'auto',
            height: 'auto',
            marginTop: 20
        }
    },
    circleCheck: {
        border: "solid 7.5px #f9effd",
        borderRadius: '50%',
        width: 75.3,
        height: 75.3,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: 32.5,
        [theme.breakpoints.down('xs')]: {
            width: 40,
            height: 40
        }
    },
    title: {
        fontSize: 22.5,
        color: '#b498db',
        marginBottom: 32.5,
        [theme.breakpoints.down('xs')]: {
            fontSize: '1.2rem'
        }
    }

})

/**
 * props.salePackages
 * Receive sale package and render it (FREE - PRO - ENTERPRISE)
 */
class ThankYou extends Component {

    constructor(props) {
        super(props)
        this.state = {

        }
    }

    UNSAFE_componentWillMount() {
    }

    UNSAFE_componentWillReceiveProps(newProps) {
    }

    render() {
        const { classes, history, title, content, success, loading, link } = this.props
        return (
            <div className={classes.container}>
                <div style={{ width: '100%', height: '100%', position: 'relative', display: 'flex', justifyContent: 'center' }}>
                    <ThankUBackgound style={{ width: '100%', height: '100%' }} />
                    <div className={classes.centerContent}>
                        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                            {/*Center content*/}
                            <div className={classes.circleCheck}>
                                <CheckIcon style={{ width: 32.7, color: '#b386f0' }} />
                            </div>
                            {/*Thanks for choosing our service!*/}
                            <div className={classes.title}>{ title }</div>

                            {/*You may start by visiting your*/}
                            <div style={{ fontSize: 15, color: '#33263b' }}>
                                { content }
                                { success && <span style={{ color: '#812cf7', cursor: 'pointer' }} onClick={e => history.push(`${link.href}`) }>&nbsp;{link.label}</span> }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    componentDidMount() {
        LocalStorage.instance.setBuyingService({})
    }

}

ThankYou.propTypes = {
    title: PropTypes.string,
    content: PropTypes.object,
    success: PropTypes.bool
};

const mapStateToProps = state => ({
});

const enhance = compose(
    translate,
    connect(
        mapStateToProps,
        {
        }
    ),
    withStyles(styles)
);

const EnhancedThankYou = enhance(ThankYou);

// We need to put the MuiThemeProvider decoration in another component
// Because otherwise the withStyles() HOC used in EnhancedLogin won't get
// the right theme
const ThankYouWithTheme = props => (
    <MuiThemeProvider theme={createTheme(lightTheme)}>
        <EnhancedThankYou {...props} />
    </MuiThemeProvider>
);

export default ThankYouWithTheme;