
export default {
    API_URL : process.env.REACT_APP_API_URL,
    WEB_URL : process.env.REACT_APP_WEB_URL,
    NLB_DOMAIN: process.env.REACT_APP_NLB_DOMAIN,
    ACCOUNT_PERMISSION_EXP_IN_SEC: 180,
    HELP_BASE_PATH: process.env.REACT_APP_HELP_BASE_PATH,
    DEBUG_LOG: process.env.REACT_APP_DEBUG_LOG === 'true',
    VTRACK_LOG_URL: process.env.REACT_APP_VTRACK_LOG_URL,
    AZURE_CLIENT_ID: process.env.REACT_APP_AZURE_APP_CLIENT_ID,
    REDIRECT_URI: process.env.REACT_APP_REDIRECT_URI,
    AZURE_READ_ONLY_APP_ID: process.env.REACT_APP_AZURE_READ_ONLY_APP_ID,
    AZURE_READ_ONLY_APP_SECRET: process.env.REACT_APP_AZURE_READ_ONLY_APP_SECRET,

    AZURE_READ_WRITE_APP_ID: process.env.REACT_APP_AZURE_READ_WRITE_APP_ID,
    AZURE_READ_WRITE_APP_SECRET: process.env.REACT_APP_AZURE_READ_WRITE_APP_SECRET,

    APACHEDS_IP_ADDRESS: process.env.REACT_APP_APACHEDS_IP_ADDRESS,
    RADIUS_PROXY_DOMAIN: process.env.REACT_APP_RADIUS_PROXY_DOMAIN,
    RADIUS_PROXY_PORT: process.env.REACT_APP_RADIUS_PROXY_PORT,
    WEBSHIELD_DOMAIN: process.env.REACT_APP_WEBSHIELD_DOMAIN,
    WEBSHIELD_PATH: process.env.REACT_APP_WEBSHIELD_PATH,
}

export const DEFAULT_MAX_LENGTH = 200
export const MAX_IMAGE_SIZE = 2000000;

export const HELP_BASE_PATH = "https://vkeystgstorageaccount.blob.core.windows.net/idm-public-container/site/V-OS_Cloud_Solution_Guide_v1.0.0.0/index.html"
export const TEMPLATE_CSV = "https://vkeystgstorageaccount.blob.core.windows.net/idm-public-container/site/share-folder/import-templates.csv";
