import {createTheme, MuiThemeProvider, withStyles} from '@material-ui/core/styles';
import React, {useEffect, useState} from 'react';
import {translate, useNotify, useRedirect} from 'react-admin';
import {connect} from 'react-redux';
import compose from 'recompose/compose';
import {lightTheme} from '../layout/themes';
import LocalStorage from '../utils/services/storage';
import ExternalLayout from './ExternalLayout';
import SalePackages from './components/SalePackages'
import {Grid} from '@material-ui/core';
import VerticalStepper from './components/VerticalStepper'
import HorizontalStepper from './components/HorizontalStepper'
import UpArrowIcon from '../Icons/UpArrow'
import DownArrowIcon from '../Icons/DownArrow'
import FinishByService from './components/FinishByService'
import qs from 'query-string'
import {PACKAGE_TYPE_ENTERPRISE, PACKAGE_TYPE_FREE, PACKAGE_TYPE_NORMAL} from './home/constant'
import ReviewPlanContainer from './home/container/ReviewPlanContainer'
import FeatureTable from './components/FeatureTable'
import {BASE_URL} from '../dataProvider'
import httpClient from '../dataProvider/httpClient'
import WaitingView from './components/WaitingView'
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import FormTitle from "../components/FormTitle";
import withWidth from "@material-ui/core/withWidth"
import SalePackagesMobile from './components/SalePackagesMobile'
import StripePayment from './home/container/StripePaymentContainer'
import styles from './home/styles'
import log from "../utils/log";

import {
    DELETE_CARD,
    EDIT_PACKAGE,
    EXTEND_PACKAGE,
    LOAD_CURRENT_ACTIVE_USERS_SUBSCRIPTION,
    LOAD_ORDER_DETAIL,
    LOAD_PACKAGES_AND_FEATURES,
    LOAD_PERIODS_BY_PACKAGE,
    LOAD_SUBSCRIPTION_DETAIL,
    SET_PRIMARY_CARD,
    SUBSCRIBE_PACKAGE,
    UPGRADE_PACKAGE
} from "./home/homeSaga";
import {
    clearHomeData,
    setLoading,
    updateCurrentStep,
    updateHoveredPackage,
    updateMessage,
    updateOrderDetail,
    updateSelectedPackage
} from './home/homeReducers'
import {useLocation} from "react-router";
import {sendContactForm} from "../rxjs-api/CustomerAPIs";
import {catchError, map} from "rxjs/operators";
import {of} from "rxjs";

const packageSteps = [
    ["Select plan", "Finish"],
    ["Select plan", "Review", "Payment", "Finish"],
    ["Select plan", "Finish"]
]

const Home = (props) => {

    const [featureCollapsed, setFeatureCollapsed] = useState(false)
    const [currentStep, setCurrentStep] = useState("0")
    const [loadingSalePackage, setLoadingSalePackage] = useState(true)
    const [hadFreeSub, setHadFreeSub] = useState(false)
    const location = useLocation()
    const notify = useNotify()
    const redirect = useRedirect()

    useEffect(() => {

        // Check OIDC login parameter
        if (qs.parse(window.location.search).client_id) {
            window.location.replace(`/login${window.location.search}`)
        }

        const { step } = location.search
        if (step)
            props.updateCurrentStep(parseInt(step))

        const { loadListPackagesAndFeatures, loadOrderDetail, loadSubscriptionDetail, loadCurrentActiveUsersSubscription } = props

        // window.addEventListener('vos-ready', loadListPackagesAndFeatures)
        loadListPackagesAndFeatures();

        const query = qs.parse(location.search)
        if (!!query.orderId ) { // After order was created => Go to payment if pro package
            loadOrderDetail(query.orderId)
        }

        // Check free existed
        if (LocalStorage.instance.getUser()) {
            _checkFreePackageExisting().then(r => console.count("_checkFreePackageExisting"));
        }

        setLoadingSalePackage(false)

        return () => {
            props.clearHomeData()
            window.removeEventListener('vos-ready', loadListPackagesAndFeatures)
        }
    }, [])

    const _checkFreePackageExisting = async () => {
        try {
            const { json: { contents } } = await httpClient(`${BASE_URL}/billing/subscription/${LocalStorage.instance.getCustomerId()}/search`, {
                method: 'POST',
                body: JSON.stringify({
                    limit: 100,
                    page: 0
                })
            })

            let existedFree = contents.find(sub => sub.tierPlanType === 'FREE')
            if (existedFree) {
                setHadFreeSub(true)
            }
        } catch (e) {

        }
    }

    useEffect(() => {
        /*if (currentStep !== location.search.step) {
            props.updateCurrentStep(parseInt(location.search.step ? location.search.step : '0' ))
            if (!location.search.step) {
                props.updateSelectedPackage(false)
            }
        }*/
        // setCurrentStep(location.search.step)
        setCurrentStep(qs.parse(location.search).step)
        // log.warn("search ::: ", qs.parse(location.search))
    }, [location.search])

    const onPackageSelected = (pack) => {
        const { history, translate, subscribePackage } = props
        log.debug(pack)
        switch (pack.tierPlanType) {
            case PACKAGE_TYPE_FREE:
                subscribePackage(pack, false)
                break;

            case PACKAGE_TYPE_NORMAL:
                history.push(`/home?tierId=${pack.id}&step=1`)
                break;

            case PACKAGE_TYPE_ENTERPRISE:
                // call api contact us
                props.setLoading(true)
                const user = LocalStorage.instance.getUser();
                sendContactForm({ emailAddress: user.email, fullName: user.firstName + " "
                        + user.lastName, phoneNumber: "" + user.mobilePhone, customerName: user.customerId })
                    .pipe(
                        map(response => {
                            redirect("/contact-us?success=true")
                            return response
                        }),
                        catchError(error => {
                            notify(translate(error.response.message), 'warning')
                            return of(error);
                        })
                    )
                    .subscribe(e => {
                        props.setLoading(false)
                    })
                break;

            default:
                log.error("ERROR: Not implement package type!")
        }
    }

    const salePackHoverChanged = (hoverPackage) => {
        props.updateHoveredPackage(hoverPackage)
        log.debug("salePackHoverChanged ", hoverPackage)
    }

    const closeDialog = () => {
        props.updateMessage(false)
    }

    const {
        classes, translate, history, width, salePackages,
        loading, features, hoveredPackage, selectedPackage, message,
    } = props

    if (!salePackages || salePackages.length === 0) {
        return null
    }

    const isMobile = width === 'xs'
    const isTablet = width === 'sm'
    // Query include tier and orderId

    const { action, upgradeFromTierId, upgradeFromTierPlanType, tierId,
        periodId, tierPlanType, orderId, subscriptionId, billing, done, step } = qs.parse(location.search)
    const isDefaultHome = !tierId && !orderId && !billing
    let focusPackage = 0

    if (salePackages.length > 0) {
        focusPackage = salePackages.indexOf(hoveredPackage)
        if (selectedPackage)
        {
            focusPackage = salePackages.indexOf(selectedPackage)
        }

        if (focusPackage < 0)
            focusPackage = 0
    }

    // currentStep = step
    /*if (done && !!selectedPackage && salePackages.length > 0) {
        currentStep = packageSteps[salePackages.indexOf(selectedPackage)] ? packageSteps[salePackages.indexOf(selectedPackage)].length - 1 : 0
    }*/

    return (
        <ExternalLayout showFooter={true} history={history}>
            <FormTitle title={"Home"} style={{ display: 'none'}} />
            <div className={classes.companyDesc}>
                {/*<div className={classes.titleFirst}>Mobile Identity &amp; Security</div>*/}
                <div style={{ height: "calc(80vw / 10)", marginBottom: 30, position: 'relative' }}>
                    <div style={{ position: 'absolute', left: '50%' }}>
                        <div className={classes.titleBackground} />
                    </div>
                    <div style={{ position: 'absolute', left: '50%', top: width === 'xs' ? "calc(80vw / 20 - 4px)" : "calc(80vw / 20 - 22px)" }}>
                        <div style={{ display: "table", position: 'relative', left: '-50%', height: 22, top: "4px" }}>
                            <span className={classes.titleSecond}>Stronger with</span> <span className={classes.vosHighlight}>V-OS</span>
                        </div>
                    </div>

                </div>
            </div>

            <div style={{ position: 'relative', background: '#5f2685', zIndex: 3, height: 15 }} id={"packages-spacing"}>
            </div>

            {/* package block */}
            <div
                style={{background: '#ffffff', position: 'relative', zIndex: 4, paddingTop: 10, paddingBottom: 30}}>
                <Grid container style={{
                    backgroundColor: "#faf9fc",
                    position: 'relative',
                    minHeight: isMobile ? 'unset': 340,
                    zIndex: 4,
                    marginTop: 0,
                    marginBottom: 0
                }}>
                    <Grid item xs={12} md={4} style={{backgroundColor: '#faf9fc', padding: !isMobile && !isTablet ? 20 : 0, paddingLeft: !isMobile && !isTablet ? '10vw': '0px'}}>
                        { !isMobile && !isTablet && <VerticalStepper steps={packageSteps[focusPackage]} currentStep={currentStep}/> }
                        { (isMobile || isTablet) && <HorizontalStepper steps={packageSteps[focusPackage]} currentStep={currentStep}/>}
                    </Grid>
                    <Grid item xs={12} md={8} style={{
                        padding: 0, minHeight: isMobile ? 'unset': 340, paddingBottom: isDefaultHome && !isMobile ? 18 : 0,
                        backgroundColor: isDefaultHome ? "unset" : "#ffffff", position: 'relative'
                    }}>
                        {/*Sale packages*/}
                        {(!tierId && !orderId) &&
                        <WaitingView loading={loading}>
                            {!isMobile &&
                            <SalePackages salePackages={salePackages} history={props.history}
                                          hoverCallback={salePackHoverChanged}
                                          hadFreeSub={hadFreeSub}
                                          onSelected={onPackageSelected}/>
                            }

                            {isMobile &&
                            <SalePackagesMobile salePackages={salePackages} history={props.history}
                                          hoverCallback={salePackHoverChanged}
                                          hadFreeSub={hadFreeSub}
                                          onSelected={onPackageSelected}/>
                            }
                        </WaitingView>
                        }

                        { (currentStep === "1" && !done) && <ReviewPlanContainer location={props.location} history={props.history}/> }
                        { currentStep === "2" && <StripePayment location={props.location} history={props.history} {...props} />}

                        {(done) &&
                        <FinishByService {...props} />
                        }

                    </Grid>
                </Grid>
            </div>

            {(isDefaultHome && isMobile && salePackages[focusPackage]) &&
            <div style={{ backgroundColor: '#f9f8fa', minHeight: '2rem', display: 'block', fontSize: '.8rem', width: '100%' }}>
                {!featureCollapsed && <div style={{marginTop: 0, paddingBottom: 15}}>
                    {features.map((feature, iRow) => {
                        if (salePackages[focusPackage].supportServiceFeatures.includes(feature.id))
                        return (
                            <div style={{textAlign: 'center', padding: '5px 0', backgroundColor: iRow % 2 === 0 ? '#eee': 'white' }}>
                                {feature.name}
                            </div>
                        )
                    })}
                </div>
                }
            </div>
            }

            {/* table feature */}
            <div style={{ background: '#ffffff', minHeight: 0, position: 'relative', zIndex: 4, padding: "0 10vw 1px 10vw", display: (isDefaultHome && !isMobile) ? "block": "none" }}>
                <div id="collapse-button" style={{
                        width: 50,
                        height: 50,
                        position: 'absolute',
                        borderRadius: '50%',
                        top: -64,
                        left: 'calc(50% - 25px)',
                        border: '1px solid #b498db',
                        background: 'white',
                        cursor: 'pointer',
                        display:  isMobile ? 'none' : 'flex'
                }} onClick={e => {
                    setFeatureCollapsed(!featureCollapsed)
                }}>
                    { featureCollapsed && <DownArrowIcon className={classes.arrowIcon} />}
                    { !featureCollapsed && <UpArrowIcon className={classes.arrowIcon} />}
                </div>
                <div style={{ display: featureCollapsed ? "none" : "block", position: 'relative' }}>
                    <WaitingView loading={loading}>
                        <FeatureTable listFeatures={features} listSalePackages={salePackages} />
                    </WaitingView>
                </div>
            </div>
            <Dialog
                aria-labelledby="form-dialog-title"
                open={!!message && message.type === 'error'}
            >
                <DialogTitle id="form-dialog-title" style={{ color: "#b498db" }}>{translate('ra.alert')}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <div style={{minWidth: isMobile ? 'unset': 400}}>
                            { !!message ? message.message : "" }
                        </div>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeDialog}>
                        { translate('ra.action.close') }
                    </Button>
                </DialogActions>
            </Dialog>
        </ExternalLayout>
    );

}

Home.propTypes = {

};

const mapStateToProps = state => ({
    loading: state.home.loading,
    cSvTemplate: state.home.cSvTemplate,
    mode: state.home.mode,
    salePackages: state.home.salePackages,
    features: state.home.features,
    hoveredPackage: state.home.hoveredPackage,
    message: state.home.message,
    selectedPackage: state.home.selectedPackage,
    currentStep: state.home.currentStep,
    orderDetail: state.home.orderDetail,
    periods: state.home.periods,
    subscriptionDetail: state.home.subscriptionDetail,
    currentActiveUsersSubscription: state.home.currentActiveUsersSubscription
});

const enhance = compose(
    translate,
    connect(
        mapStateToProps,
        {
            loadListPackagesAndFeatures: () => ({
                type: LOAD_PACKAGES_AND_FEATURES
            }),
            subscribePackage: (pack, additionalData) => ({
                type: SUBSCRIBE_PACKAGE, payload: {
                    selectedPackage: pack,
                    additionalData: additionalData
                }
            }),
            upgradePackage: (pack, additionalData) => ({
                type: UPGRADE_PACKAGE, payload: {
                    selectedPackage: pack,
                    additionalData: additionalData
                }
            }),
            extendPackage: (pack, additionalData) => ({
                type: EXTEND_PACKAGE, payload: {
                    selectedPackage: pack,
                    additionalData: additionalData
                }
            }),
            editPackage: (pack, additionalData) => ({
                type: EDIT_PACKAGE, payload: {
                    selectedPackage: pack,
                    additionalData: additionalData
                }
            }),
            loadOrderDetail: (orderId) => ({
                type: LOAD_ORDER_DETAIL,
                payload: orderId
            }),
            loadSubscriptionDetail: (subscriptionId) => ({
                type: LOAD_SUBSCRIPTION_DETAIL,
                payload: subscriptionId
            }),
            loadCurrentActiveUsersSubscription: (subscriptionId) => ({
                type: LOAD_CURRENT_ACTIVE_USERS_SUBSCRIPTION,
                payload: subscriptionId
            }),
            loadPeriods: (tierPlanId) => ({
                type: LOAD_PERIODS_BY_PACKAGE,
                payload: tierPlanId
            }),
            deleteCard: (cardId) => ({
                type: DELETE_CARD,
                payload: cardId
            }),
            setPrimaryCard: (cardId) => ({
                type: SET_PRIMARY_CARD,
                payload: cardId
            }),
            setLoading,
            updateHoveredPackage,
            updateSelectedPackage,
            updateMessage,
            updateCurrentStep,
            updateOrderDetail,
            clearHomeData
        }
    ),
    withStyles(styles),
    withWidth()
);

const EnhancedHome = enhance(Home);

// We need to put the MuiThemeProvider decoration in another component
// Because otherwise the withStyles() HOC used in EnhancedLogin won't get
// the right theme
const HomeWithTheme = props => (
    <MuiThemeProvider theme={createTheme(lightTheme)}>
        <EnhancedHome {...props} />
    </MuiThemeProvider>
);

export default HomeWithTheme;
