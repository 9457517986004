import {createTheme, MuiThemeProvider, withStyles} from '@material-ui/core/styles';
import {Email, VpnKey} from '@material-ui/icons';
import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';
import {Notification, translate, useLogin, useNotify, userLogin} from 'react-admin';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import compose from 'recompose/compose';
import {getFormSubmitErrors, propTypes} from 'redux-form';
import FormTitle from '../components/FormTitle';
import ExternalLayout from '../external/ExternalLayout';
import {renderField} from '../external/signup/renderField';
import {lightTheme} from './themes';
import LocalStorage from '../utils/services/storage';
import './login_form.css'
import classNames from 'classnames'
import WaitingView from "../external/components/WaitingView";
import {Field, Form} from 'react-final-form'
import {capitalizeFirstLetter, trim} from "../utils/StringUtils";
import Button from "@material-ui/core/Button";
import log from '../utils/log'
import {parse} from "query-string";
import {VkeyQRCode} from "v-key-qrcode"
import {Tab, Tabs} from "@material-ui/core";

const styles = theme => ({

    main: {
        transition: "background 0.5s ease-in-out",
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        width: '41%',
        maxWidth: 900,
        [theme.breakpoints.down('md')]: {
            width: '70%'
        },
        [theme.breakpoints.down('sm')]: {
            width: '80%'
        },
        [theme.breakpoints.down('xs')]: {
            width: '90%'
        },
    },
    loginContent: {
        transition: "background 0.5s ease-in-out",
        ...theme.Login.loginContent,
        height: 330,
        [theme.breakpoints.down('xs')]: {
            padding: 15,
            background: 'unset'
        }
    },
    card: {
        minWidth: 300,
        marginTop: '6em',
    },
    avatar: {
        margin: '1em',
        display: 'flex',
        justifyContent: 'center',
    },
    icon: {
        backgroundColor: theme.palette.secondary.main,
    },
    hint: {
        marginTop: '1em',
        display: 'flex',
        justifyContent: 'center',
        color: theme.palette.grey[500],
    },
    form: {
        // padding: '0 1em 1em 1em',
    },
    input: {
        marginTop: '1em',
        "&:-webkit-autofill": {
            WebkitTextFillColor: "#fff !important",
            WebkitBoxShadow: "0 0 0 1000px white inset !important",
        }
    },
    actions: {
        padding: '0 1em 1em 1em',
    },
    btnSubmit: {
        ...theme.SignUp.leftPanel.btnSubmit,
        [theme.breakpoints.down('sm')]: {
            width: 240,
            fontSize: '0.875rem'
        }
    },
    validationError: {
        color: 'red',
        fontSize: '.8em',
        textAlign: 'left',
        paddingLeft: 52,
        paddingTop: 5,
        fontStyle: 'italic'
    },
    default: {
        marginBottom: 15
    },
    flex: theme.SignUp.leftPanel.formControl,
    // Signup left panel
    leftPanelRoot: {
        ...theme.SignUp.leftPanel.root,
        paddingRight: 0,
        paddingLeft: theme.SignUp.leftPanel.root.paddingRight

    },
    pinkButtonDefault: {
        border: 'none',
        height: 40,
        marginTop: 30
    },
    leftPanelTitle: theme.SignUp.leftPanel.title,
    leftPanelDescription: theme.SignUp.leftPanel.description,
    formTitle: theme.SignUp.leftPanel.formTitle,
    inputIcon: theme.SignUp.leftPanel.inputIcon,
	mb2: {
		marginBottom: 16
	},
	message: {
		"fontSize":"16px",
		"textAlign":"center",
		"width":"100%",
		"marginTop":"1.3em",
		"marginLeft":"auto",
		"marginRight":"auto",
		"color":"rgb(143, 143, 143)"
	}
});
const validate = values => {
    const errors = {}

    let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (!re.test(String(values.username).toLowerCase())) {
        errors.username = 'resources.signUp.validation.email_regex'
    }
    if (!values.username) {
        errors.username = 'resources.signUp.validation.required'
    }
    if (!values.password) {
        errors.password = 'resources.signUp.validation.required'
    }

    return errors
}

const TAB_QRCODE = 1, TAB_USERPASS = 2;

const StyledTabs = withStyles({
    root: {
        marginBottom: '16px'
    },
    indicator: {
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: 'rgb(180, 152, 219)',
        '& > span': {
            width: '100%',
            backgroundColor: 'rgb(180, 152, 219)',
        },
        '&$selected': {
            color: 'rgb(180, 152, 219)',
        },
    }
})((props) => <Tabs {...props} TabIndicatorProps={{children: <span/>}}/>);

const StyledTab = withStyles((theme) => ({
    root: {
        textTransform: 'none',
        color: '#B5B4BB',
        fontSize: theme.typography.pxToRem(20),
        '&:focus': {
            opacity: 1,
            color: 'rgb(180, 152, 219)',
        },
        '&$selected': {
            color: 'rgb(180, 152, 219)',
        },
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
    },
    selected: {
        color: 'rgb(180, 152, 219)',
    }
}))((props) => <Tab disableRipple {...props} />);

let isMobile = {
    Android: function () {
        return navigator.userAgent.match(/Android/i);
    },
    BlackBerry: function () {
        return navigator.userAgent.match(/BlackBerry/i);
    },
    iOS: function () {
        return navigator.userAgent.match(/iPhone|iPad|iPod/i);
    },
    Opera: function () {
        return navigator.userAgent.match(/Opera Mini/i);
    },
    Windows: function () {
        return navigator.userAgent.match(/IEMobile/i) || navigator.userAgent.match(/WPDesktop/i);
    },
    any: function () {
        return (isMobile.Android() || isMobile.BlackBerry() || isMobile.iOS() || isMobile.Opera() || isMobile.Windows());
    }
};

function Login(props) {
    const [loading, setLoading] = useState(false);
    const [tab, setTab] = useState(TAB_USERPASS)
    const {classes, handleSubmit, dispatch, translate, history, width, showNotification, isLoading} = props;
    const params = parse(window.location.search)

    useEffect(() => {
        log.debug("Login page loaded params: ", params)
        if (LocalStorage.instance.getToken() != null) {
            // logged in
            history.push('/home')
        }

        if (!params.client_id) {
            window.location.replace("/api/authentication/oauth2/authorize/custom")
        }
    }, [])

    let errors = {}
    const handleValidate = (e) => {
        let a = validate(e)
        errors = a
        return a;
    }
    const signIn = useLogin();
    let notify = useNotify();
    const login = async auth => {
        console.info("Auth info: ", auth);
        auth.state = params.state;
        setLoading(true);
        // calculation next url
        const buyService = LocalStorage.instance.getBuyingService()
        const {isBuying, tierId} = buyService
        let redirectPath = ""
        if (props.location.state)
            redirectPath = props.location.state.nextPathname
        else if (isBuying)
            redirectPath = `/home?tierId=${tierId}`
        else
            redirectPath = "/"

        signIn(auth, redirectPath)
            .then(e => {
                setTimeout(() => {
                    setLoading(false)
                }, 1000)
            })
            .catch((e) => {
                notify(translate(e), "warning")
                setTimeout(() => {
                    setLoading(false)
                }, 1000)
            });

        LocalStorage.instance.setBuyingService(null)

    }

    const handleTabChange = (event, newValue) => {
        setTab(newValue);
    };

    return (
        <ExternalLayout history={history}>
            <div>
                <div style={{height: '100vh', display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
                    <div className={classes.main}>
                        <div style={{width: '100%', marginBottom: 24}}>
                            <FormTitle title={translate('sign_in')} className={classes.formTitle}
                                       style={{color: 'white', fontSize: 27, textAlign: "center"}}/>
                        </div>
                        <div className={classNames(classes.loginContent, "login-form")} id={loading ? "btnDraw" : ""}>
                            <WaitingView loading={isLoading}>
                                {/* <hr /> */}

                                <StyledTabs
                                    variant="fullWidth"
                                    value={tab}
                                    indicatorColor="primary"
                                    textColor="primary"
                                    onChange={handleTabChange}
                                >
                                    <StyledTab label="QR Code Login" value={TAB_QRCODE}/>
                                    <StyledTab label="Credentials Login" value={TAB_USERPASS}/>
                                </StyledTabs>

                                {tab === TAB_USERPASS && <>
                                    <Form onSubmit={login} validate={handleValidate}
                                          render={({handleSubmit, form, submitting, pristine, values}) => (
                                              <form onSubmit={handleSubmit}>
                                                  <div className={classes.form}>
                                                      <Field
                                                          name="username"
                                                          type="text"
                                                          component={renderField}
                                                          label="Email"
                                                          classes={classes}
                                                          icon={<Email className={classes.inputIcon}/>}
                                                          autoFocus={true}
                                                          parse={trim}
                                                          trans={translate}
                                                          required
                                                      />

                                                      <Field
                                                          name="password"
                                                          type="password"
                                                          label="Password"
                                                          component={renderField}
                                                          classes={classes}
                                                          icon={<VpnKey className={classes.inputIcon}/>}
                                                          trans={translate}
                                                      />

                                                      <Button
                                                          style={{
                                                              fontSize: 14.06,
                                                              color: "#fcfcff",
                                                              width: 157,
                                                              height: 45,
                                                              display: "block",
                                                              margin: '0 auto',
                                                              cursor: "pointer",
                                                              marginTop: 20,
                                                              marginBottom: 20,
                                                              backgroundColor: "#8615bf",
                                                              borderRadius: "none"
                                                          }}
                                                          size={"medium"}
                                                          // classes={classes}
                                                          color={"secondary"}
                                                          variant={"contained"}
                                                          disabled={loading}
                                                          type={"submit"}
                                                          // id={"btnDraw"}
                                                      >
                                                          Sign In
                                                      </Button>
                                                      {/*<PinkButton classes={classes} label={translate('Sign In')}*/}
                                                      {/*		  type={"submit"} disabled={loading}/>*/}

                                                      {!pristine && errors && Object.keys(errors).length > 0 &&
                                                      <div style={{
                                                          color: '#ff4e00',
                                                          marginTop: 15
                                                      }}> {/*Form was touched and has error*/}
                                                          {capitalizeFirstLetter(translate(errors[Object.keys(errors)[0]], {field_name: Object.keys(errors)[0]}))}
                                                      </div>
                                                      }
                                                  </div>
                                              </form>
                                          )}>
                                    </Form>
                                    {/*<div class={"signin-break-wrapper"}></div>*/}
                                    <div style={{display: 'flex', marginTop: 36}}>
                                        <div><Link to="/forgot-password" style={{
                                            color: '#ffffff',
                                            display: 'block',
                                            fontSize: '14px',
                                            textDecoration: 'none',
                                            flex: 0
                                        }}>{"Forgot password"}</Link></div>
                                        <div style={{flex: 1}}/>
                                        <div style={{width: '50%'}}>
                                            <Link to="/signup" style={{
                                                display: 'block',
                                                fontSize: '14px',
                                                textDecoration: 'none',
                                                color: '#FF9D00',
                                                flex: 0,
                                                textAlign: 'right'
                                            }}>
                                                {translate('resources.signUp.create_new_vos_cloud_account')}
                                            </Link>
                                        </div>
                                    </div>
                                </>}

                                {tab === TAB_QRCODE && <>
                                    <div className={classNames(classes.mb2, classes.message)}>
                                        {!isMobile.any() ? "Scan with V-Key app to log in" : "Tab QR code to log in with V-Key app"}
                                    </div>
                                    <div className={classes.mb2} style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                                        <VkeyQRCode clientId={params.client_id} redirectUrl={params.redirect_uri}
                                                    serverUrl={`https://${window.location.hostname}`}
                                                    state={params.state} size={170}
                                                    logoImage={`${process.env.PUBLIC_URL}/assets/images/v-key-logo-with-color.png`}
                                                    logoWidth={60}
                                                    logoOpacity={1}
                                                    qrStyle={"dots"}
                                                    bgColor={"transparent"}
                                                    fgColor={"white"}
													quietZone={12}
                                            // onRejected={(tokenSerial => alert("User rejected token=" + tokenSerial))}
                                        />
                                    </div>
									<div className={classes.message}>Don't have V-Key app?
										<a href={"/docs/help/vkey-apps"} style={{ color: "white" }} target={"_blank"}> Download here</a></div>
                                </>}

                            </WaitingView>

                        </div>
                        <Notification/>
                    </div>
                </div>
            </div>

        </ExternalLayout>
    );


}

Login.propTypes = {
    ...propTypes,
    authProvider: PropTypes.func,
    classes: PropTypes.object,
    previousRoute: PropTypes.string,
    translate: PropTypes.func.isRequired,
    userLogin: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
    return {
        isLoading: state.admin.loading > 0,
        submitErrors: getFormSubmitErrors('signIn')(state),
        notifications: state.admin.notifications
    }
};

const enhance = compose(
    translate,
    /*reduxForm({
        form: 'signIn',
        enableReinitialize: true,
        validate: (values, props) => {
            const errors = {};
            const { translate } = props;
            if (!values.username) {
                errors.username = translate('ra.validation.required');
            }
            if (!values.password) {
                errors.password = translate('ra.validation.required');
            }
            return errors;
        },
    }),*/
    connect(
        mapStateToProps,
        {
            userLogin,
        }
    ),
    withStyles(styles)
);

const EnhancedLogin = enhance(Login);

// We need to put the MuiThemeProvider decoration in another component
// Because otherwise the withStyles() HOC used in EnhancedLogin won't get
// the right theme
const LoginWithTheme = props => (
    <MuiThemeProvider theme={createTheme(lightTheme)}>
        <EnhancedLogin {...props} />
    </MuiThemeProvider>
);

export default LoginWithTheme;
