import { stringify } from 'query-string';
import {
    fetchUtils,
    GET_LIST,
    GET_ONE,
    GET_MANY,
    GET_MANY_REFERENCE,
    CREATE,
    UPDATE,
    UPDATE_MANY,
    DELETE,
    DELETE_MANY,
    showNotification
} from 'react-admin';
import LocalStorage from '../utils/services/storage';
import axios, { AxiosRequestConfig, post, put } from "axios";
export const GET_DASHBOARD_DETAIL = 'GET_DASHBOARD_DETAIL'

export const SEARCH_VARIABLE_QUERY = 'SEARCH_VARIABLE_QUERY'

/**
 * Maps react-admin queries to a simple REST API
 *
 * The REST dialect is similar to the one of FakeRest
 * @see https://github.com/marmelab/FakeRest
 * @example
 * GET_LIST     => GET http://my.api.url/posts?sort=['title','ASC']&range=[0, 24]
 * GET_ONE      => GET http://my.api.url/posts/123
 * GET_MANY     => GET http://my.api.url/posts?filter={ids:[123,456,789]}
 * UPDATE       => PUT http://my.api.url/posts/123
 * CREATE       => POST http://my.api.url/posts
 * DELETE       => DELETE http://my.api.url/posts/123
 */
export default (apiUrl, httpClient = fetchUtils.fetchJson) => {

    /**
     * @param {String} type One of the constants appearing at the top if this file, e.g. 'UPDATE'
     * @param {String} resource Name of the resource to fetch, e.g. 'posts'
     * @param {Object} params The data request params, depending on the type
     * @returns {Object} { url, options } The HTTP request parameters
     */
    const convertDataRequestToHTTP = (type, resource, params) => {
        let customerId = LocalStorage.instance.getCustomerId()
        let url = '';
        const options = {};
        //// log.debug('params', params);
        let token = LocalStorage.instance.getToken();
        options.headers = new Headers({
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Sec-Fetch-Site': 'none',
            'Access-Control-Expose-Headers': 'Content-Range',
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Methods': 'POST, GET, PUT, OPTIONS',
            'Authorization': 'Bearer ' + token
        });
        options.mode = 'no-cors';
        switch (type) {

            case GET_DASHBOARD_DETAIL:
                // alert(GET_DASHBOARD_DETAIL)
                url = `${apiUrl.replace("/api", "")}/api/dashboards/uid/${params.uid}?token=${LocalStorage.instance.getToken()}`;
                // url = 'http://localhost:8060/grafana/api/datasources/proxy/1/loganalyticsazure/a568cbca-6fa2-4de9-a882-7150dfea6dfa/query?query=ContainerLog%0A%7C%20where%20TimeGenerated%20between(datetime(2020-05-17%2016%3A29%3A47)%20..%20datetime(2020-05-20%2016%3A29%3A47))%0A%7C%20where%20LogEntry%20contains%20%22%5Bradius-request%5D%22%0A%7C%20parse%20LogEntry%20with%20%2A%20%22customerId%20%3D%20%22%20customerId%20%22%2C%20%22%20%2A%20%22username%20%3D%20%22%20username%20%22%20%22%0A%7C%20where%20customerId%20%3D%3D%20%225d26e7ce8185630029ee639e%22%0A%7C%20order%20by%20TimeGenerated%20desc%20%0A%7C%20distinct%20username%0A%7C%20limit%2010&token=eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJjbG91ZC1hZG1pbkB2LWtleS5jb20iLCJ1SWQiOiI1ZDI2ZTdjZThkOWY2NzAwMjkwOGIwMzMiLCJjdXN0b21lcklkIjoiNWQyNmU3Y2U4MTg1NjMwMDI5ZWU2MzllIiwicm9sZUlkcyI6WyI1Y2IwNGVlMTA3NzY1YmYzMDFlNjBlMDQiXSwiaWF0IjoxNTg5OTQ1NDMzLCJleHAiOjE1OTAwMzE4MzN9.fjGcELv1410wu3Ecl2l2U6qRUl23dX2yiOvB3K7PVxc'
                break;

            default:
                throw new Error(`Unsupported fetch action type ${type}`);
        }
        return { url, options };
    };

    /**
     * @param {Object} response HTTP response from fetch()
     * @param {String} type One of the constants appearing at the top if this file, e.g. 'UPDATE'
     * @param {String} resource Name of the resource to fetch, e.g. 'posts'
     * @param {Object} params The data request params, depending on the type
     * @returns {Object} Data response
     */
    const convertHTTPResponse = (response, type, resource, params) => {
        const { headers, json } = response;
        console.log("grafana response", {json})
        switch (type) {
            default:
                return { data: json };
        }
    };

    /**
     * @param {string} type Request type, e.g GET_LIST
     * @param {string} resource Resource name, e.g. "posts"
     * @param {Object} payload Request parameters. Depends on the request type
     * @returns {Promise} the Promise for a data response
     */
    const requestHandler = (type, resource, params) => {
        // simple-rest doesn't handle filters on UPDATE route, so we fallback to calling UPDATE n times instead
        if (type === UPDATE_MANY) {
            return Promise.all(
                params.ids.map(id =>
                    httpClient(`${apiUrl}/${resource}/${id}`, {
                        method: 'PUT',
                        body: JSON.stringify(params.data),
                    })
                )
            ).then(responses => ({
                data: responses.map(response => response.json),
            }));
        }
        // simple-rest doesn't handle filters on DELETE route, so we fallback to calling DELETE n times instead
        if (type === DELETE_MANY) {
            return Promise.all(
                params.ids.map(id =>
                    httpClient(`${apiUrl}/${resource}/${id}`, {
                        method: 'DELETE',
                    })
                )
            ).then(responses => ({
                data: responses.map(response => response.json),
            }));
        }

        const { url, options } = convertDataRequestToHTTP(
            type,
            resource,
            params
        );

        return httpClient(url, options).then(response =>
            convertHTTPResponse(response, type, resource, params)
        );
    };

    return requestHandler;
};