import {makeStyles, useTheme} from '@material-ui/core/styles';
import HomeIcon from '@material-ui/icons/Home';
import DashboardIcon from '@material-ui/icons/Dashboard';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import React, {Fragment} from 'react';
import {AppBar, getResources, MenuItemLink, UserMenu, useTranslate} from 'react-admin';
// import AppBar from '@material-ui/core/AppBar';
import {connect} from 'react-redux';
import vkeyLogo from './v-key-logo.png';
import CommonMenu from "../components/CommonMenu";
import LocalStorage from "../utils/services/storage";
import UserMenuCustom from './UserMenuCustom'

const useStyles = makeStyles((theme) => ({
        title: {
            flex: 1,
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
        },
        spacer: {
            flex: 1,
            textAlign: 'center'
        },
        dashboardAppBar: {
            // background: 'black !important',
            // minHeight: 70

        },
        icon: {
            color: "rgba(0,0,0,0.544)"
        }
    }))
;


const CustomUserMenu = ({...props}) => {
    const translate = useTranslate()
    return (
        <div style={{display: "inline"}}>
            <UserMenu {...props}>
                <MenuItemLink
                    to="/home"
                    primaryText={translate('pos.home_page')}
                    leftIcon={<HomeIcon color={"action"}/>}
                />
                <MenuItemLink
                    to="/token-pack"
                    primaryText={translate('pos.dashboard.name')}
                    leftIcon={<DashboardIcon color={"action"}/>}
                />
                <MenuItemLink
                    to="/my-account/profile"
                    primaryText={translate('resources.myAccounts.menuName')}
                    leftIcon={<AccountCircleIcon color={"action"}/>}
                />

            </UserMenu>
        </div>
    );
}

const CustomAppBar = ({history, ...props}) => {
    const theme = useTheme();
    let user = LocalStorage.instance.getUser()
    const classes = useStyles(theme);
    return (
        <Fragment>
            <AppBar {...props}
                // id="AppBar"
                    userMenu={user ? <UserMenuCustom user={user}/> : null}
                    className={classes.dashboardAppBar}
                    ref={ref => {
                        const intevalT = setInterval(() => {

                            let userIconNode = document.querySelector('#AppBar button:last-child')
                            if (!localStorage) return;

                            let user = localStorage.user
                            if (!userIconNode || !user)
                                return;

                            user = JSON.parse(user)
                            userIconNode.style.cssText = 'width: unset'
                            let userNameNode = document.createElement('label')
                            userNameNode.id = 'label-username'
                            userNameNode.innerText = user.username
                            userNameNode.style.cssText = 'font-size: 16px; margin-left: 5px; font-family: sans-serif; white-space: nowrap'
                            if (!document.getElementById('label-username'))
                                userIconNode.appendChild(userNameNode)
                            clearInterval(intevalT)
                        }, 200)
                    }}
            >
                <img src={vkeyLogo} alt="VKEY" style={{height: '35px', cursor: 'pointer'}}
                     onClick={() => window.globalHistory.push('/home')}
                />

                <span className={classes.spacer}>
                {/* (originalScope == SCOPE_VKEY && currentScope == SCOPE_CUSTOMER) && <span id="current-customer">{ customerName }</span> */}
            </span>
                <CommonMenu style={{marginBottom: "0px !important", flex: 5}}/>

            </AppBar>
        </Fragment>
    )
}

const mapStateToProps = state => ({
    resources: getResources(state),
    currentScope: state.menu.scope
});

// export default connect(mapStateToProps)(withStyles(styles)(CustomAppBar));
export default connect(mapStateToProps)(CustomAppBar);