import purple from '@material-ui/core/colors/purple';
import { createTheme, MuiThemeProvider, withStyles } from '@material-ui/core/styles';
import React from 'react';
import { translate } from 'react-admin';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { lightTheme } from '../../../layout/themes';
import { EDIT_ACTION, EXTEND_ACTION, UPGRADE_ACTION } from "../../../subscription/SubscriptionList";
import WaitingView from "../../components/WaitingView";
import StripeCheckoutForm from '../component/Stripe/StripeCheckoutForm';
import { getSubscriptionById } from '../homeApi';
import {
    updateCurrentStep,
    updateHoveredPackage,
    updateOrderDetail,
    updateSelectedPackage,
    updateSubscriptionDetail
} from "../homeReducers";
import {
    CALCULATION_ORDER_AMOUNT,
    EDIT_PACKAGE,
    EXTEND_PACKAGE,
    LOAD_CUSTOMER_CARDS,
    LOAD_PERIODS_BY_PACKAGE,
    LOAD_SUBSCRIPTION_DETAIL,
    PAY_AN_ORDER,
    SUBSCRIBE_PACKAGE,
    UPGRADE_PACKAGE
} from "../homeSaga";
import StepBaseComponent from '../StepBaseComponent';
import log from "../../../utils/log";

const myPink = purple[500];

const styles = theme => ({
    signupContent: {
        ...theme.Login.loginContent,
        backgroundColor: 'white',
        [theme.breakpoints.down('xs')]: {
            padding: 15,
            background: 'unset'
        }
    },
    cssLabel: {
        '&$cssFocused': {
            color: myPink,
        },
    },
    cssFocused: {},
    cssUnderline: {
        '&:after': {
            borderBottomColor: myPink,
        },
    },
    default: {
        width: '100%',
        marginBottom: 10,
        // display: 'flex'
    },
    formContent: {
        width: 'calc(100%)',
        margin: '0 auto',
        marginTop: 15
    },
    btnSubmit: theme.SignUp.leftPanel.btnSubmit,
    MuiGrid: {
        container: {
            display: 'flex'
        }
    },
    validationError: {
        color: 'red',
        fontSize: '.8em',
        textAlign: 'left',
        paddingLeft: 52,
        paddingTop: 5,
        fontStyle: 'italic'
    },
    flex: theme.SignUp.leftPanel.formControl,
    // Signup left panel
    leftPanelRoot: theme.SignUp.leftPanel.root,
    leftPanelTitle: theme.SignUp.leftPanel.title,
    leftPanelDescription: theme.SignUp.leftPanel.description,
    formTitle: theme.SignUp.leftPanel.formTitle,
    inputIcon: theme.SignUp.leftPanel.inputIcon
})

class StripePaymentContainer extends StepBaseComponent {

    componentDidMount() {
        const { updateOrderDetail, loadCards, salePackages, selectedPackage, updateSelectedPackage, calculateAmount, subscriptionDetail, loadSubscriptionDetail, removeUsers } = this.props
        const { action, subscriptionId, periodId, tierId, numberOfUsers, orderId } = this.parseQuery()

        log.warn('StripePaymentContainer UNSAFE_componentWillMount - queryString', this.props.location.search)
        log.warn('StripePaymentContainer UNSAFE_componentWillMount', { action, subscriptionId, subscriptionDetail, periodId, tierId, numberOfUsers, orderId })

        if (!orderId) {

            let order = {}
            if (action && action === UPGRADE_ACTION) {
                order = { action, numberOfUsers, paymentPlanIds: [periodId, tierId], subscriptionId }

            } else if (action === EXTEND_ACTION) {
                log.warn('StripePaymentContainer UNSAFE_componentWillMount - removeUsers', removeUsers)
                //let removeUsers = [] // not yet implemented
                order = { action, numberOfUsers, paymentPlanIds: [periodId, tierId], subscriptionId, removeUsers }

            } else if (action === EDIT_ACTION) {
                order = { action, numberOfUsers, paymentPlanIds: [periodId, tierId], subscriptionId }

            } else {
                // Create new order
                order = { numberOfUsers, paymentPlanIds: [periodId, tierId] }
            }

            if (!selectedPackage) {
                updateSelectedPackage(salePackages.filter(pack => pack.id === tierId)[0])
            }

            // Need calculation amount
            updateOrderDetail(order)

            let req = {
                action,
                numberOfUsers,
                tierId,
                periodId,
                subscriptionId,
                subscriptionDetail
            };

            // get subscription detail
            if (subscriptionId && (subscriptionDetail == undefined || subscriptionDetail == null)) {
                getSubscriptionById(subscriptionId).then((rsp) => {
                    req.subscriptionDetail = rsp.json;
                    calculateAmount(req);
                });
            } else {
                calculateAmount(req)
            }

        } else {

        }

        loadCards()
    }



    render() {

        const { classes, history, loading } = this.props

        return (
            <div className={ classes.signupContent }>
                <div style={{ fontSize: 22.5, display: 'flex', flexDirection: 'column', justifyContent: 'center',
                    textAlign: 'left', marginBottom: 30 }}>Payment</div>
                <WaitingView loading={loading}>
                    <StripeCheckoutForm {...this.props} />
                </WaitingView>
            </div>
        )
    }

}

const mapStateToProps = state => ({
    cards: state.home.cards,
    salePackages: state.home.salePackages,
    periods: state.home.periods,
    orderDetail: state.home.orderDetail,
    selectedPackage: state.home.selectedPackage,
    loading: state.home.loading,
    removeUsers: state.home.removeUsers
});


const enhance = compose(
    connect(
        mapStateToProps,
        {
            updateHoveredPackage,
            updateSelectedPackage,
            updateCurrentStep,
            updateSubscriptionDetail,
            loadPeriods: (tierPlanId) => ({
                type: LOAD_PERIODS_BY_PACKAGE,
                payload: tierPlanId
            }),
            loadCards: () => ({
                type: LOAD_CUSTOMER_CARDS
            }),
            loadSubscriptionDetail: (subscriptionId) => ({
                type: LOAD_SUBSCRIPTION_DETAIL,
                payload: subscriptionId
            }),
            updateOrderDetail,
            subscribePackage: (pack, additionalData) => ({
                type: SUBSCRIBE_PACKAGE, payload: {
                    selectedPackage: pack,
                    additionalData: additionalData
                }
            }),
            payAnOrder: (customerId, orderId, stripeTokenId, sourceId) => ({
                type: PAY_AN_ORDER,
                payload: {
                    customerId, orderId, stripeTokenId, sourceId
                }
            }),
            calculateAmount: (orderDetail) => ({
                type: CALCULATION_ORDER_AMOUNT,
                payload: orderDetail
            }),
            upgradePackage: (pack, additionalData) => ({
                type: UPGRADE_PACKAGE, payload: {
                    selectedPackage: pack,
                    additionalData: additionalData
                }
            }),
            extendPackage: (pack, additionalData) => ({
                type: EXTEND_PACKAGE, payload: {
                    selectedPackage: pack,
                    additionalData: additionalData
                }
            }),
            editPackage: (pack, requirePayment, additionalData) => ({
                type: EDIT_PACKAGE, payload: {
                    selectedPackage: pack,
                    requirePayment,
                    additionalData: additionalData
                }
            })
        }
    ),
    translate,
    withStyles(styles)
);

const EnhancedStripePayment = enhance(StripePaymentContainer);

// We need to put the MuiThemeProvider decoration in another component
// Because otherwise the withStyles() HOC used in EnhancedLogin won't get
// the right theme
const StripePaymentWithTheme = props => (
    <MuiThemeProvider theme={createTheme(lightTheme)}>
        <EnhancedStripePayment {...props} />
    </MuiThemeProvider>
);

export default StripePaymentWithTheme;
