import React, {Component} from 'react'
import {withStyles} from "@material-ui/core";
import CheckIcon from "../../Icons/CheckIcon";
import './FeatureTable.css'

const styles = {

}

class FeatureTable extends Component {

    render() {
        // Calculate to bold border
        let countRowCheck = 0
        let bolderBorder = false

        const { listFeatures, listSalePackages } = this.props
        return (
            <table width="100%" style={{ color: "#33263b", fontSize: 15, borderSpacing: 0, marginBottom: 20 }}>
                <col width="35%" />
                <tbody>
                { listFeatures.map((feature, iRow) => {
                    let currentCountCellCheck = 0
                    // Calculate count check before render row
                    listSalePackages.map(p => {
                        if (p.supportServiceFeatures.includes(feature.id)) currentCountCellCheck ++
                    })

                    if (countRowCheck !== 0 && currentCountCellCheck !== countRowCheck)
                        bolderBorder = true
                    else
                        bolderBorder = false

                    const isLastRow = iRow === listFeatures.length - 1

                    return (
                        <tr>
                            <td className={'feature-cell-container'} style={{
                                    paddingLeft: 25, borderBottom: !isLastRow ? "1px solid #dbd8e0" : "",
                                    height: 40.74, borderTop: bolderBorder ? '.1px solid #c0a2d6': 'none',
                                    backgroundColor: iRow % 2 === 0 ? '#eee': 'white'
                                }}
                                onMouseEnter={e => {
                                    if (!feature.description)
                                        return
                                    const title = e.currentTarget.firstChild;
                                    title.classList ? title.classList.add('hover') : title.className += ' hover';

                                    // For tooltip
                                    const tooltip = e.currentTarget.childNodes[1]
                                    if (tooltip) {
                                        tooltip.classList ? tooltip.classList.add('hover') : tooltip.className += ' hover';
                                    }
                                }}
                                onMouseLeave={e => {
                                    if (!feature.description)
                                        return
                                    const title = e.currentTarget.firstChild;
                                    if (title.classList) {
                                        title.classList.remove('hover')
                                    } else {
                                        title.className = title.className.replace('hover', '')
                                    }

                                    // For tooltip
                                    const tooltip = e.currentTarget.childNodes[1]
                                    if (tooltip) {
                                        if (tooltip.classList) {
                                            tooltip.classList.remove('hover')
                                        } else {
                                            tooltip.className = tooltip.className.replace('hover', '')
                                        }
                                    }
                                }}>
                                <div className={'feature-name'}>{feature.name}</div>
                                {feature.description && <div className={'feature-tooltip'}>{feature.description}</div> }
                            </td>
                            { listSalePackages.map((pack, iCell) => {
                                let check = pack.supportServiceFeatures.includes(feature.id)

                                if (iCell === listSalePackages.length - 1)
                                {
                                    countRowCheck = currentCountCellCheck
                                }
                                return (
                                    <td style={{ borderBottom: !isLastRow ? `1px solid #dbd8e0` : '', height: 45.74, textAlign: 'center',
                                        borderTop: bolderBorder ? '.1px solid #c0a2d6': 'none', backgroundColor: iRow % 2 === 0 ? '#eee': 'white'}}>
                                        { check && <CheckIcon style={{ width: 18, color: '#2699fb' }}/> }
                                    </td>
                                )
                            }) }
                        </tr>
                    )
                }) }
                </tbody>
            </table>
        )
    }
}

export default withStyles(styles)(FeatureTable)