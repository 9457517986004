import React, {Fragment, useState} from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import {makeStyles} from '@material-ui/core/styles';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import {AccountCircle, PowerSettingsNew} from '@material-ui/icons';
import {MenuItemLink, userLogout as userLogoutAction, UserMenu, useRedirect, useLogout} from 'react-admin'
import {connect, useDispatch} from 'react-redux';
import DashboardIcon from '@material-ui/icons/Dashboard';
import {Link} from "react-router-dom";
import ButtonMenuPopper from "../components/ButtonMenuPopper";
import useTheme from "@material-ui/core/styles/useTheme";
import useTranslate from "ra-core/esm/i18n/useTranslate";
import {Hidden} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import Drawer from "@material-ui/core/Drawer";
import clsx from "clsx";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";
import HomeIcon from "@material-ui/icons/Home";
import MenuList from "@material-ui/core/MenuList";
import MenuItem from "@material-ui/core/MenuItem";
const useStyle = makeStyles((theme) => ({

    root: {
        display: 'flex',
    },
    paper: {
        marginRight: theme.spacing(2),
    },
    textWhite: {
        color: 'white',
        textTransform: 'unset'
    },
    menuBtn: {
        color: 'white',
        // fontSize: "16px",
        textTransform: "capitalize",
        "&:hover,&:focus,&:after": {
            color: 'rgb(255, 157, 0)'
        }

    },
    sectionMobile: {
        display: 'flex',
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },
}));

const UserMenuCustom = (props) => {
    const [open, setOpen] = useState(null);
    const theme = useTheme();
    const classes = useStyle(theme);
    const translate = useTranslate();
    const {user, userLogout} = props;
    const redirectTo = useRedirect()
    const dispatch = useDispatch()
    const logout = useLogout();

    const handleClose = (href) => {
        if (href && href !== '')
            redirectTo(href)
    };
    const MenuLists = [
        {text: translate('pos.home_page'), href: "/home", icon: HomeIcon},
        {text: translate('pos.dashboard.name'), href: "/token-pack", icon: DashboardIcon },
        {text: translate('resources.myAccounts.menuName'), href: "/my-account/profile", icon: AccountCircleIcon },
    ]

    const CustomUserMenu = ({...props}) => {
        const translate = useTranslate()
        return (
            <div style={{display: "inline"}}>
                <MenuList className={props.className}>
                    {MenuLists.map((item, index) => (
                        <MenuItemLink
                            to={item.href}
                            primaryText={item.text}
                            leftIcon={<item.icon color={"action"}/>}
                        />
                    ))}
                    <MenuItem onClick={e => {
                        logout()
                    }}>
                        <PowerSettingsNew
                            color={"action"}
                            style={{paddingRight: '1.2rem'}}
                        /> {translate('ra.auth.logout')}
                    </MenuItem>
                </MenuList>
            </div>
        );
    }


    const [state, setState] = React.useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
    });
    const mobileMenuAnchor = "right";
    const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setState({...state, [anchor]: open});
    };

    const list = (anchor) => (
        <div
            className={clsx(classes.list, {
                [classes.fullList]: anchor === 'top' || anchor === 'bottom',
            })}
            style={{backgroundColor: "#533475", height: "100%"}}
            role="presentation"
            onClick={toggleDrawer(anchor, false)}
            onKeyDown={toggleDrawer(anchor, false)}
        >
            <List>

                {MenuLists.map((item, index) => (
                    <ListItem button key={item.href}>
                        <ListItemIcon>{<item.icon />}</ListItemIcon>
                        <ListItemText primary={item.text}
                                      onClick={() => handleClose(item.href)}/>
                    </ListItem>

                ))}

            </List>
            <Divider/>

        </div>
    );
    return (
        <div className={classes.root}>
            <Hidden smDown>
                <ButtonMenuPopper
                    open={open}
                    setOpen={setOpen}
                    buttonIcon={<AccountCircle/>}
                    component={CustomUserMenu}
                    id={"user-popper-menu"}
                    arrow={true}
                    buttonText={user.username}
                />
            </Hidden>
            <div className={classes.sectionMobile}>
                <IconButton
                    onClick={toggleDrawer(mobileMenuAnchor, true)}
                    color="inherit"
                >
                    <AccountCircle/>
                </IconButton>

            </div>
            <Drawer anchor={mobileMenuAnchor} open={state[mobileMenuAnchor]}
                    onClose={toggleDrawer(mobileMenuAnchor, false)}>
                {list(mobileMenuAnchor)}
            </Drawer>


        </div>
    );

}

UserMenuCustom.propTypes = {
    classes: PropTypes.object.isRequired,
};
export default connect(
    null,
    {
        userLogout: userLogoutAction
    }
)(UserMenuCustom);
// export default connect(null, {userLogout})(withStyles(styles)(translate(MenuListComposition)));