import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import {Link} from 'react-router-dom';
import vkeyLogo from '../layout/v-key-logo.png';
import {connect, useDispatch} from 'react-redux';
import classNames from 'classnames'
import LocalStorage from '../utils/services/storage';
import Hidden from '@material-ui/core/Hidden';
import useTranslate from "ra-core/esm/i18n/useTranslate";
import makeStyles from "@material-ui/core/styles/makeStyles";
import MailIcon from '@material-ui/icons/Mail';
import {useTheme} from "@material-ui/core";
import Drawer from "@material-ui/core/Drawer";
import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Divider from "@material-ui/core/Divider";
import ListItemText from "@material-ui/core/ListItemText";
import InboxIcon from '@material-ui/icons/MoveToInbox';
import MenuIcon from '@material-ui/icons/Menu';
import clsx from "clsx";
import IconButton from "@material-ui/core/IconButton";
import ListSubheader from "@material-ui/core/ListSubheader";
import {createTheme, MuiThemeProvider} from "@material-ui/core/styles";
import {lightTheme} from "./themes";
import CommonMenu from "../components/CommonMenu";
import {setSidebarVisibility, useRedirect} from "react-admin";
import UserMenuCustom from "./UserMenuCustom";

const styleColorTransition = {
    background: 'rgb(92, 46, 119)',
    transition: 'background .5s ease',
    boxShadow: '0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12)',

}

const useStyles = makeStyles((theme) => ({
    body: {
        fontFamily: "Roboto !important",
        fontWeight: 400,
    },
    nested: {
        paddingLeft: theme.spacing(4),
    },
    root: {
        flexGrow: 1,
        position: 'fixed',
        width: '100%',
        top: 0,
        zIndex: 1000
    },
    homeHeader: {
        background: 'transparent',
        boxShadow: 'none',
        "&:hover": {
            background: "rgba(47,47,47,0.3)",
            transition: 'background .5s ease',
            boxShadow: '0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12)',
        },

    },
    header: {
        background: "rgb(92, 46, 119)",
        boxShadow: 'none',
    },
    flex: {
        flexGrow: 1,
        marginLeft: 10
    },
    menuButton: {
        marginLeft: -12,
        marginRight: 20,
    },
    hover: styleColorTransition,
    offsetTop: styleColorTransition,
    textWhite: {
        color: 'white',
        textTransform: 'unset'
    },
    homeLink: {
        [theme.breakpoints.down('sm')]: {
            flex: 1
        }
    },
    menuBtn: {
        color: 'white',
        fontSize: "16px",
        textTransform: "capitalize",
        "&:hover,&:focus,&:after": {
            color: 'rgb(255, 157, 0)'
        }

    },
    hiddenMenu: {
        marginRight: "10px"
    },
    sectionMobile: {
        display: 'flex',
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },
    listHeader: {
        color: "white"
    },
    dropdown: {
        transition: theme.transitions.create(["transform"], {
            duration: theme.transitions.duration.short
        })
    },
    dropdownOpen: {
        transform: "rotate(-180deg)"
    },
    dropdownClosed: {
        transform: "rotate(0)"
    }
}));

function CustomAppBar(props) {
    const [hover, setHover] = useState(false);
    const [offsetTop, setOffsetTop] = useState(false);
    const [solOpen, setSolOpen] = useState(false);
    const [guiOpen, setGuiOpen] = useState(false);
    const [sideBarOpen, setSideBarOpen] = useState(true);
    const translate = useTranslate();
    const theme = useTheme();
    const classes = useStyles(theme);
    const redirectTo = useRedirect()

    window.addEventListener('scroll', e => handleScroll())

    const toggleSideBar = e => {
        setSideBarOpen(pre => !pre)
    }
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(setSidebarVisibility(sideBarOpen))
    }, [sideBarOpen])

    useEffect(() => {
        if (guiOpen === true) {
            setSolOpen(false)
        }
    }, [guiOpen, setSolOpen])

    useEffect(() => {
        if (solOpen === true) {
            setGuiOpen(false)
        }
    }, [solOpen])

    const handleScroll = () => {
        setOffsetTop(window.pageYOffset > 1);
        setHover(false);

    }


    const handleClose = (href) => {
        if (href.indexOf("https://") > 0) {
            window.open(href, "_blank")
        }
        if (href && href !== '')
            redirectTo(href)
    };
    const hoverUrlList = [
        "", "/home", "/", "/login", "/signup"
    ]

    const {forceHover, history} = props;

    let user = LocalStorage.instance.getUser()

    let rootClasses = [classes.root]
    if (hover && hoverUrlList.indexOf(window.location.pathname) !== -1 && window.pageYOffset === 0)
        rootClasses.push(classes.homeHeader)


    if (offsetTop)
        rootClasses.push(classes.hover)

    if (forceHover)
        rootClasses.push(classes.hover)

    const solMenu = [
        {text: "VPN/RADIUS 2FA", href: "/docs/intro/solutions/2fa-vpn-radius"},
        {text: "Office 365/SAML 2FA", href: "/docs/intro/solutions/2fa-o365-saml"},
    ]
    const guiMenu = [
        {text: "VPN/RADIUS 2FA", href: "/docs/help/2fa-for-vpn"},
        {text: "Office 365/SAML 2FA", href: "/docs/help/2fa-for-o365"},
    ]

    const overview = [
        {text: translate('resources.home.overview'), href: "/docs/intro/overview"},
    ]
    const faq = [
        {text: "FAQ", href: "/docs/intro/faq"},
        {text: "Ticket", href: "https://v-key.atlassian.net/servicedesk"},
        // {text: "Contact Us", href: "#"},
    ]

    const list = (anchor) => (
        <div
            className={clsx(classes.list, {
                [classes.fullList]: anchor === 'top' || anchor === 'bottom',
            })}
            style={{backgroundColor: "#533475", height: "100%"}}
            role="presentation"
            onClick={toggleDrawer(anchor, false)}
            onKeyDown={toggleDrawer(anchor, false)}
        >

            <List>
                {overview.map((item, index) => (
                    <ListItem button key={item.href}>
                        <ListItemIcon>{index % 2 === 0 ? <InboxIcon/> : <MailIcon/>}</ListItemIcon>
                        <ListItemText primary={item.text} onClick={() => handleClose(item.href)}/>
                    </ListItem>
                ))}

            </List>
            <Divider/>
            <List aria-labelledby="sol-list-subheader"
                  component="nav"
                  subheader={
                      <ListSubheader component="div" id="sol-list-subheader" style={{color: "white"}}>
                          {translate('resources.home.solutions')}
                      </ListSubheader>
                  }
                  className={classes.listHeader}

            >
                {solMenu.map((item, index) => (
                    <ListItem button key={item.href}>
                        <ListItemIcon>{index % 2 === 0 ? <InboxIcon/> : <MailIcon/>}</ListItemIcon>
                        <ListItemText primary={item.text} onClick={() => handleClose(item.href)}/>
                    </ListItem>
                ))}

            </List>
            <Divider/>
            <List aria-labelledby="gui-list-subheader"
                  component="nav"
                  subheader={
                      <ListSubheader component="div" id="gui-list-subheader" style={{color: "white"}}>
                          {translate('resources.home.guidelines')}
                      </ListSubheader>
                  }
                  className={classes.listHeader}
            >
                {guiMenu.map((item, index) => (
                    <ListItem button key={item.href}>
                        <ListItemIcon>{index % 2 !== 0 ? <InboxIcon/> : <MailIcon/>}</ListItemIcon>
                        <ListItemText primary={item.text} onClick={() => handleClose(item.href)}/>
                    </ListItem>
                ))}
            </List>
            <Divider/>
            <List>
                {faq.map((item, index) => (
                    <ListItem button key={item.href}>
                        <ListItemIcon>{index % 2 !== 0 ? <InboxIcon/> : <MailIcon/>}</ListItemIcon>
                        <ListItemText primary={item.text} onClick={() => handleClose(item.href)}/>
                    </ListItem>
                ))}
            </List>

        </div>
    );
    const [state, setState] = React.useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
    });
    const mobileMenuAnchor = "left";
    const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setState({...state, [anchor]: open});
    };
    return (
        <div className={classNames(rootClasses)} onMouseEnter={() => {
            setHover(true);
        }}
             onMouseLeave={() => {
                 setHover(false);
             }}>
            <AppBar position="static" className={classes.header}>
                <Toolbar style={{minHeight: "45px", paddingLeft: 5}}>
                    <IconButton
                        onClick={toggleSideBar}
                        color="inherit"
                    >
                        <MenuIcon className={[
                            classes.dropdown,
                            sideBarOpen
                                ? classes.dropdownOpen
                                : classes.dropdownClosed
                        ]}/>
                    </IconButton>
                    <div className={classes.sectionMobile} style={{marginRight: "10px"}}>
                        <IconButton
                            onClick={toggleDrawer(mobileMenuAnchor, true)}
                            color="inherit"
                        >
                            <MenuIcon/>
                        </IconButton>

                    </div>

                    <a onClick={() => handleClose("/home")} className={classes.homeLink}>
                        <img src={vkeyLogo} alt="VKEY" style={{height: '35px'}}/>
                    </a>
                    <Hidden smDown>
                        <Typography variant="title" color="inherit" className={classes.flex}>
                            V-OS Cloud
                        </Typography>
                    </Hidden>

                    {((LocalStorage.instance.getToken() === undefined || !user) && ["/login"].indexOf(window.location.pathname) === -1) &&
                    <span>
                        <Button className={classes.menuBtn} color="inherit"
                                component={props => <Link to="/login" {...props} />}
                        >
                            {translate('sign_in')}
                        </Button>
                    </span>
                    }
                    {((LocalStorage.instance.getToken() === undefined || !user) && ["/signup"].indexOf(window.location.pathname) === -1) &&
                    <span>
                        <Button className={classes.menuBtn} style={{color: 'rgb(255, 157, 0)'}}
                                component={props => <Link to="/signup" {...props} />}
                        >
                            {translate('sign_up')}
                        </Button>
                    </span>
                    }

                    {
                        (LocalStorage.instance.getToken() != null && user !== undefined) ? (
                            <UserMenuCustom user={user}/>
                        ) : null
                    }

                </Toolbar>
                <CommonMenu/>

            </AppBar>
            <Drawer anchor={mobileMenuAnchor} open={state[mobileMenuAnchor]}
                    onClose={toggleDrawer(mobileMenuAnchor, false)}>
                {list(mobileMenuAnchor)}
            </Drawer>
        </div>
    );


}

CustomAppBar.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({isLoading: state.admin.loading > 0});

const CustomAppBarWithTheme = props => (
    <MuiThemeProvider theme={createTheme(lightTheme)}>
        <CustomAppBar {...props} />
    </MuiThemeProvider>
);
export default connect(
    mapStateToProps,
    {}
)(CustomAppBarWithTheme);
// export default VkeyAppBarWithTheme;