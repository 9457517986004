import React from "react";

const SvgSalePackageFree = props => (
  <svg viewBox="0 0 60 39.598" {...props}>
    <path
      fill="currentColor"
      d="M50 34.648a4.968 4.968 0 004.975-4.95L55 4.95A4.99 4.99 0 0050 0H10a4.99 4.99 0 00-5 4.95V29.7a4.99 4.99 0 005 4.95H0v4.95h60v-4.95zM10 4.95h40V29.7H10z"
      className="sale_package_free_svg__cls-1"
    />
  </svg>
);

export default SvgSalePackageFree;
