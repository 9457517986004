import React from "react";

const SvgSalePackageEnterprise = props => (
  <svg viewBox="0 0 65 65" {...props}>
    <path
      fill="currentColor"
      d="M32.5 19.172V8.51H5.673v47.98h53.654V19.172zM16.4 51.159h-5.362v-5.331h5.365zm0-10.662h-5.362v-5.331h5.365zm0-10.662h-5.362v-5.332h5.365zm0-10.662h-5.362v-5.332h5.365zm10.734 31.986h-5.361v-5.331h5.365zm0-10.662h-5.361v-5.331h5.365zm0-10.662h-5.361v-5.332h5.365zm0-10.662h-5.361v-5.332h5.365zm26.827 31.986H32.5v-5.331h5.365v-5.331H32.5v-5.331h5.365v-5.331H32.5v-5.332h21.461zm-5.365-21.324H43.23v5.331h5.365zm0 10.662H43.23v5.331h5.365z"
      data-name="Path 868"
    />
  </svg>
);

export default SvgSalePackageEnterprise;
