import React, { Component, Fragment } from 'react';

export default class Hoverable extends Component {

    constructor(props) {
        super(props)
        this.state = {
            isHover: false
        }
    }

    render() {

        let style = {
            opacity: this.state.isHover ? 0.5 : 1
        }

        return (<div style={style} {...this.props}
            onMouseEnter={e => this.setState({ isHover: true })}
            onMouseLeave={e => this.setState({ isHover: false})}
            >
            { this.props.children }
        </div>);
    }

}