import {ajax} from "rxjs/ajax";
import {BASE_URL} from "../dataProvider";

export const sendContactForm = (values: any) => ajax({
    url: `${BASE_URL}/customer/customer/contact-form`,
    method: 'POST',
    headers: {
        'Content-Type': 'application/json'
    },
    body: {
        ...values
    }
});