import { withStyles } from '@material-ui/core/styles';
import { AttachMoney, Delete, Done } from '@material-ui/icons';
import get from 'lodash/get';
import React, { Children, cloneElement, Fragment, useEffect, useRef, useState } from 'react';
import {
    Datagrid,
    DateField,
    List,
    ReferenceField,
    refreshView as refreshViewAction,
    TextField,
    useDataProvider,
    useTranslate
} from 'react-admin';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import DashboardPageWrapper from '../components/DashboardPageWrapper';
import VkeyAmountField from '../components/VkeyAmountField';
import VKeyListButton from '../components/VkeyListButton';
//import AccountBulkActionsToolbar from './AccountBulkActionsToolbar';
import LocalStorage, { MY_ACC_DASHBOARD } from '../utils/services/storage';
import { APPROVE_PAYMENT, CANCEL_ORDER } from './data-provider';
import OrderFilter from './OrderFilter';
import OrderStatusField from './OrderStatusField';
import log from "../utils/log";

export const STATUS_ORDER_APPROVED = "APPROVED"

export const STATUS_ORDER_SUBMITTED = "SUBMITTED"

export const STATUS_ORDER_CANCELED = "CANCELED"

const ServiceInstanceField = ({ source, sortable, record = {}, ...rest }) => {
    
    let serviceInstanceObj = get(record, source);

    if ((serviceInstanceObj != undefined && serviceInstanceObj != null)) {
        return (
            <Link to={`/service-launch/` + serviceInstanceObj.id}>{serviceInstanceObj.instanceName}</Link>
    );
    } else {
        return (<span></span>) ;
    }
    
};

const OrderListActionToolbar = withStyles({
    toolbar: {
        alignItems: 'center',
        display: 'flex',
    },
})(({ classes, children, ...props }) => (
    <div className={classes.toolbar}>
        {(children && Children.map(children, button => (button == null || button === 0 || button === '0') ? null : cloneElement(button, props))) || null}
    </div>
));

const ConditionalActionsField = ({ record, dashboard, cancelOrder, approvePayment, translate, completePayment, ...rest }) =>
{
    const customerId = LocalStorage.instance.getCustomerId();
    return (
        <Fragment>
            { (dashboard === MY_ACC_DASHBOARD && record.status === STATUS_ORDER_SUBMITTED && customerId === record.customerId) &&
                <div>
                    <VKeyListButton title={translate("resources.orders.buttons.cancel.label")} handleClick={() => {
                        if (cancelOrder)
                            cancelOrder(record)
                    }}><Delete /></VKeyListButton>

                    {record.amount != null &&
                        <VKeyListButton title={translate("resources.orders.buttons.pay.label")} handleClick={() => {
                            if (completePayment)
                                completePayment(record)
                        }}><AttachMoney/></VKeyListButton>
                    }
                </div>
            }

            {(dashboard === MY_ACC_DASHBOARD && record.status === STATUS_ORDER_SUBMITTED && customerId === null) &&
                <VKeyListButton title={translate('resources.orders.buttons.approve.label')} handleClick={() => {
                    if (approvePayment) approvePayment(record)
                }}><Done /></VKeyListButton>
            }

            { (dashboard === MY_ACC_DASHBOARD && (record.status === STATUS_ORDER_APPROVED || record.status === STATUS_ORDER_CANCELED)
                && customerId === record.customerId) &&
            <div style={{ height: 35 }}></div>
            }
        </Fragment>
    )
}

const PlanNameField = ({ record = {} }) => <span>{record.tierPaymentPlan.description}</span>;

PlanNameField.defaultProps = { label: 'Plan' };

const styles = {
    myAccountOrder: {

    }
}

const OrderList = props => {

    const [customerId, setCustomerId] = useState(null)
    const [action, setAction] = useState(null)
    const dataProvider = useDataProvider()
    const wrapper = useRef();
    const translate = useTranslate()

    useEffect(() => {
        setCustomerId(LocalStorage.instance.getCustomerId())
    }, [])

    useEffect(() => {
        log.debug("Wrapper changed: ", wrapper.current)
    }, [wrapper])

    const cancelOrder = (order) => {
        setAction(CANCEL_ORDER)
        wrapper.current.confirm({ body: "Do you want cancel this order?", record: order })
    }

    const approvePayment = (order) => {
        setAction(APPROVE_PAYMENT)
        wrapper.current.confirm({
            body: "This action will create token pack, service instance. Are you sure?",
            record: order
        });
    }

    const completePayment = (order) => {
        wrapper.current.loading(true)
        props.history.push('/home?orderId=' + order.id + `&tierId=${order.tierPaymentPlan.id}&step=2`);
    }

    const acceptCallback = (order) => {
        wrapper.current.loading(true)
        dataProvider(action, 'order', { orderId: order.id, customerId: order.customerId }).then(response => {
            props.refreshView()
            wrapper.current.loading(false)
        })
    }

    const { dashboard } = props;

    return (
        <DashboardPageWrapper title={false} ref={wrapper}
            acceptCallback={acceptCallback}
        >
            <List
                {...props}
                filters={<OrderFilter displaySearchKeyword={ !customerId } />}
                //bulkActionButtons={<AccountBulkActionsToolbar translate={translate} />}
                bulkActionButtons={false}
                exporter={false}
                className={dashboard === MY_ACC_DASHBOARD ? "myaccount-list": null}
                >
                <Datagrid style={{ marginTop: 25 }}>
                    {/*<ImageField source="serviceTemplate.iconUrl" title="icon" label={''} />*/}
                    {/*<TextField sortable={false} source="serviceTemplate.templateName" label={'resources.orders.fields.service.label'} />*/}
                    {/*<ServiceInstanceField sortable={false} source="serviceInstance" label={'resources.serviceLaunchs.fields.instanceName.label'} />*/}
                    <ReferenceField source="customerId" reference="customer" allowEmpty linkType={false}>
                        <TextField sortable={false} source="companyName"  />
                    </ReferenceField>
                    <PlanNameField />
                    <VkeyAmountField sortable={false} source="amount" currencySource="currency" label={translate('resources.orders.fields.amount.label')} />
                    <DateField sortable={false} source="createdDate" label={translate('resources.orders.fields.createDate.label')} />
                    {/*<DateField sortable={false} source="expireDate" label={translate('resources.orders.fields.expireDate.label')} />*/}
                    {/* <TextField sortable={false} source="status" label={translate('resources.orders.fields.status.label')} /> */}
                    <OrderStatusField sortable={false} source="status" label={translate('resources.orders.fields.status.label')} />

                    <ConditionalActionsField dashboard={dashboard} cancelOrder={cancelOrder}
                                             approvePayment={approvePayment} completePayment={completePayment} translate={translate} />
                </Datagrid>
            </List>
        </DashboardPageWrapper>
    );
}

const mapStateToProps = state => ({
     loading: state.admin.loading
});

export default connect(
    mapStateToProps,
    {
        refreshView: refreshViewAction
    }
)(OrderList);
/*
const enhance = compose(
    translate,
    connect(
        mapStateToProps,
        {
            refreshView: refreshViewAction
        }
    ),
    withStyles(styles)
);

export default enhance(OrderList);*/
