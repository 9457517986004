import simpleRestProvider from './simpleRestProvider';
import customerDataProvider from '../customer/data-provider';
import accountDataProvider from '../account/data-provider';
import assetDataProvider from '../asset/data-provider';
import roleDataProvider from '../role/data-provider';
import serviceTemplateDataProvider from '../service-template/data-provider';
import serviceLaunchDataProvider from '../service-launch/data-provider';
import themeDataProvider from '../wla_v2/data-provider';
import httpClient from './httpClient';
import constants from '../constants';
import customProvider from './customProvider';
import LocalStorage from '../utils/services/storage';
import denyServiceUsersDataProvider from '../service-launch/deny-service-users-data-provider';
import seamlessServiceUsersDataProvider from '../service-launch/seamless-service-users-data-provider';
import subscriptionDataProvider from '../subscription/data-provider';
import userDataProvider from '../internal/users/userDataProvider'
import homeDataProvider from '../external/home/dataProvider'
import trustedIpDataProvider from '../internal/trusted-ip/dataProvider'
import vosCloudConfigDataProvider from '../vosCloudConfig/data-provider'
import samlSettingDataProvider from '../internal/vkey-saml-settings/samlSettingDataProvider'
import userDetailsDataProvider from '../internal/user-details/userDetailsDataProvider';
import grafanaProvider from "./grafanaProvider";
import otpConfigProvider from "../internal/otp-config/dataProvider"
import userKeyCloakDataProvider from "../internal/users/vkeyldap/userKeyCloakDataProvider";
import connectorDataProvider from "./connectorDataProvider";
import activationCodeDataProvider from "./activationCodeDataProvider";
import OTPCodeDataProvider from "./OTPCodeDataProvider";
import tokenPackDataProvider from "./tokenPackDataProvider";
import grafanaPanelDataProvider from "./grafanaPanelDataProvider";
import baseSpringDataProvider from "./baseSpringDataProvider";

let customerId = LocalStorage.instance.getCustomerId()
//export const BASE_URL = "https://localhost:8060"
export const BASE_URL = constants.API_URL;

const dataProviders = [
    /* dinh.tran */
    { dataProvider: connectorDataProvider(BASE_URL + '/idm-config/reactive', httpClient), resources: ['ldap_2fa', 'radius'] },
    { dataProvider: activationCodeDataProvider(BASE_URL + '/idm-config/v2/reactive', httpClient), resources: ['activation-code'] },
    { dataProvider: OTPCodeDataProvider(BASE_URL + '/idm-config/v2/reactive', httpClient), resources: ['otp'] },
    { dataProvider: connectorDataProvider(BASE_URL + '/idm-config/reactive', httpClient), resources: ['oidc', 'saml', 'saml2', 'urls', 'ldap'] },
    { dataProvider: simpleRestProvider(BASE_URL + '/idm-config/connectors', httpClient), resources: ['https'] },
    { dataProvider: simpleRestProvider(BASE_URL + '/idm-vos/' + customerId, httpClient), resources: ['soft-token', 'soft-token/reset'] },
    { dataProvider: simpleRestProvider(BASE_URL + '/idm-ldap', httpClient), resources: ['test-connection'] },
    { dataProvider: vosCloudConfigDataProvider(BASE_URL + '/idm-config', httpClient), resources: ['vos-cloud-config'] },
    { dataProvider: tokenPackDataProvider(BASE_URL + '/idm-config', httpClient), resources: ['token-pack-v2', 'token-pack/send-mail'] },
    { dataProvider: customProvider(BASE_URL + '/idm-config', httpClient), resources: ['token-pack', 'token-pack/send-mail'] },
    { dataProvider: trustedIpDataProvider(BASE_URL + '/idm-config', httpClient), resources: ['white-list-ips'] },
    { dataProvider: userDataProvider(BASE_URL + '/user-management', httpClient), resources: ['users', 'users/user-admin-reset', 'send-ts-apin', 'users/inactive', 'users-realm', 'dot/reset-face-id'] },
    { dataProvider: userDetailsDataProvider(BASE_URL + '/user-management', httpClient), resources: ['user-details'] },
    { dataProvider: denyServiceUsersDataProvider(BASE_URL + '/user-management', httpClient), resources: ['deny_service_users'] },
    { dataProvider: seamlessServiceUsersDataProvider(BASE_URL + '/user-management', httpClient), resources: ['seamless_service_users'] },
    { dataProvider: homeDataProvider(BASE_URL + '/billing', httpClient), resources: ['payment_plan'] },
    { dataProvider: samlSettingDataProvider(BASE_URL + '/idm-config', httpClient), resources: ['saml-setting'] },
    { dataProvider: userKeyCloakDataProvider(BASE_URL + '/user-management', httpClient), resources: ['users/keycloak' ]},
    /* END dinh.tran */

    { dataProvider: customerDataProvider(BASE_URL + '/customer', httpClient), resources: ['customer'] },
    { dataProvider: accountDataProvider(BASE_URL, httpClient), resources: ['account', 'account_role'] },
    { dataProvider: assetDataProvider(BASE_URL + '/account', httpClient), resources: ['asset'] },
    { dataProvider: roleDataProvider(BASE_URL + '/account', httpClient), resources: ['role'] },

    { dataProvider: themeDataProvider(BASE_URL + '/whitelabelapp', httpClient), resources: ['theme'] },

    { dataProvider: serviceTemplateDataProvider(BASE_URL + '/service-mgmt', httpClient), resources: ['service-template', 'template/all'] },
    { dataProvider: serviceLaunchDataProvider(BASE_URL + '/service-mgmt', httpClient), resources: ['service-launch'] },
    { dataProvider: subscriptionDataProvider(BASE_URL + '/billing', httpClient), resources: ['subscription', 'billing', 'order'] },
    { dataProvider: grafanaProvider(BASE_URL + '/grafana', httpClient), resources: ['grafana'] },
    { dataProvider: grafanaPanelDataProvider(BASE_URL + '/grafana', httpClient), resources: ['grafana-panel-detail'] },
    { dataProvider: otpConfigProvider(BASE_URL + '/idm-config', httpClient), resources: ['otp-config'] },
    { dataProvider: tokenPackDataProvider(BASE_URL, httpClient), resources: ['audit'] },
];

export default (type, resource, params) => {
    // log.debug('Finding dataProvider for resource ' + resource)
    const dataProvider = dataProviders.find(dp => dp.resources.includes(resource)).dataProvider;
    // log.debug(dataProvider)
    return dataProvider(type, resource, params);
}
