import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import './stepper.css'

const styles = theme => ({
  root: {
    width: '90%',
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  actionsContainer: {
    marginBottom: theme.spacing(2),
  },
  resetContainer: {
    padding: theme.spacing(3),
  },
});

class VerticalLinearStepper extends React.Component {

    render() {
        let { steps, currentStep, stepClickCallback } = this.props;
        if (!currentStep) currentStep = 0
        return (
            <div class="container">
                { steps ? steps.map((step, iStep) => {
                    let className = "step"
                    if (iStep < currentStep) {
                        className = `${className} completed`
                    } else if (iStep == currentStep) {
                        className = `${className} active`
                    }

                    let title, description
                    if (typeof step === 'string') {
                        title = step
                        description = false
                    }

                    if (typeof step === 'object') {
                        title = step.title
                        description = step.description
                    }

                    return (
                        <div class={className} onClick={() => { if (stepClickCallback) stepClickCallback(iStep) }}
                             style={{ cursor: stepClickCallback ? 'pointer' : 'unset' }}>
                            <div class="v-stepper">
                                <div class="circle"></div>
                                {(iStep !== steps.length - 1) && <div class="line"></div> }
                            </div>
                    
                            <div class="content">
                                <div style={{ fontWeight: description ? 'bold': 'normal' }}>{ title }</div>
                                { description && <div>{description}</div>}
                            </div>
                        </div>
                    )
                }) : null }
            </div>
        
        );
    }
}

VerticalLinearStepper.propTypes = {
  steps: PropTypes.array,
  currentStep: PropTypes.number
};

export default withStyles(styles)(VerticalLinearStepper);