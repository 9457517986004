import React, { Component } from "react";
import Slider from "react-slick";
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import Hoverable from '../buyservice/component/Hoverable';
import SaleFreeIcon from '../../Icons/SalePackageFree'
import SaleProIcon from '../../Icons/SalePackagePro'
import SaleEnterpriseIcon from '../../Icons/SalePackageEnterprise'
import {
    PACKAGE_TYPE_FREE, PACKAGE_TYPE_NORMAL, PACKAGE_TYPE_ENTERPRISE,
    PACKAGE_ACTION_TITLE
} from '../home/constant'

import LocalStorage from '../../utils/services/storage';

const styles = {
    // Package block
    container: {
        zIndex: 4,
        backgroundColor: 'white',
        height: '100%',
        /*paddingRight: '10vw',
        paddingLeft: 75*/
    },
    packageBar: {
        backgroundColor: '#f6f2fc',
        display: 'flex',
        height: '2rem',
        marginBottom: 10,
        fontSize: '.8rem'
    }
}

const saleIcons = [
    <SaleFreeIcon />,
    <SaleProIcon />,
    <SaleEnterpriseIcon />
]

const packagesDesc = [
    <div style={{ verticalAlign: "middle", display: 'table-cell' }}>
        MAX 10 USERS
    </div>,
    <div style={{ verticalAlign: "middle", display: 'table-cell', lineHeight: 1.925 }}>
        <span style={{ fontSize: 24 }}>$3</span> /USER / MONTH <br/>
        MIN 5 USERS
    </div>
]

const Package = withStyles({
    wrapper: {
        // marginRight: 25,
        // backgroundColor: 'grey',
        // borderTop: "4px solid #f6f2fc",
        height: '100%',
        flex: 1,
        display: 'none'
    },
    hovered: {
        // borderTop: "4px solid #b498db",
        display: 'inline-block'
    },
    title: {
        marginTop: 10,
        fontSize: 20,
        textAlign: 'center',
        color: '#33263b',
        display: 'flex',
        justifyContent: 'center'
    },
    icon: {
        width: 35,
        height: 35,
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column'
        // marginTop: 75,
        // marginLeft: 'auto',
        // marginRight: 'auto',
        // backgroundColor: "grey"
    },
    colorHover: {
        color: '#b498db'
    },
    price: {
        marginTop: 10,
        textAlign: 'center',
        color: '#634277',
        fontSize: 12,
        height: 70,
        display: 'table',
        width: '100%'
    }
})(({ classes, children, isHover, pack, iPack, onSelected, history, hadFreeSub, ...props }) => {
    const signedIn = !!LocalStorage.instance.getToken()
    let label = !signedIn ? "SIGN UP" : (hadFreeSub && pack.tierPlanType === 'FREE') ?
        'Go To Dashboard' : PACKAGE_ACTION_TITLE[pack.tierPlanType];
    if (!signedIn && pack.tierPlanType === 'ENTERPRISE') {
        label = PACKAGE_ACTION_TITLE[pack.tierPlanType]
    }

    return (
        <div className={isHover ? classNames(classes.wrapper, classes.hovered, classes.colorHover): classes.wrapper} {...props }>
            <div className={isHover ? classNames(classes.title, classes.colorHover): classes.title }>
                <div className={isHover ? classNames(classes.icon, classes.colorHover) : classes.icon }>
                    {
                        saleIcons[iPack]
                    }
                </div>
                {/*<div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', marginLeft: 10 }}>{ pack.name }</div>*/}
            </div>
            <div className={classes.price}>
                { packagesDesc[iPack] }
            </div>
            <Hoverable>
                <PinkButton label={label} onClick={e => {
                    // log.debug(history)
                    if (!signedIn)
                    {
                        LocalStorage.instance.setBuyingService({
                            isBuying: true,
                            tierId: pack.id
                        })

                        history.push("/signup")
                        return;
                    }

                    if (hadFreeSub && pack.tierPlanType === 'FREE') {
                        history.push('/token-pack')
                        return
                    }

                    if (onSelected)
                        onSelected(pack)
                }}/>
            </Hoverable>
            {
                iPack === 1 && <div style={{ fontSize: '.8rem', color: '#e25911', fontWeight: 'bold', textAlign: 'center' }}>
                    Free 1-Month Trial
                </div>
            }
        </div>
    )
});

const PinkButton = withStyles({
    default: {
        backgroundImage: "linear-gradient(195deg, #d645cb, #532b8b)",
        fontSize: '.8rem',
        color: "#f2eafd",
        width: '30%',
        minHeight: 30,
        display: "block",
        margin: '0 auto',
        cursor: "pointer",
        marginTop: 10,
        marginBottom: 10
    }
})(({ classes, children, label, ...props }) => {
    return (
        <button className={classes.default} {...props}>{label}</button>
    )
});

/**
 * props.salePackages
 * Receive sale package and render it (FREE - PRO - ENTERPRISE)
 */
class SalePackages extends Component {

    constructor(props) {
        super(props)
        this.state = {
            salePackages: [],
            iHover: 0
        }
    }

    UNSAFE_componentWillMount() {
        this.setState({ salePackages: this.props.salePackages })
    }

    UNSAFE_componentWillReceiveProps(newProps) {
        this.setState({ salePackages: newProps.salePackages })
    }

    render() {
        const { classes, hoverCallback, onSelected, hadFreeSub, salePackages } = this.props
        return (
            <div className={classes.container}>
                <div className={classes.packageBar}>
                    {
                        salePackages.map((pack, iPack) =>
                            <div style={{ flex: 1, textAlign: 'center',
                                    display: 'flex', flexDirection: 'column',
                                    justifyContent: 'center',
                                    borderBottom: iPack === this.state.iHover ? "3px solid #b498db": 'none',
                                    cursor: 'pointer'
                                }}
                                 onClick={e => {
                                     // Callback
                                     if (hoverCallback)
                                         hoverCallback(salePackages[iPack])
                                     this.setState({iHover: iPack})
                                 }}
                            >
                                {pack.name}
                            </div>
                        )
                    }
                </div>
                <div style={{ display: 'flex', height: '100%' }}>
                {
                    salePackages.map((pack, iPack) => 
                        <Package pack={pack} iPack={iPack} isHover={iPack === this.state.iHover}
                            hadFreeSub={hadFreeSub}
                            onMouseEnter={e => {
                                // Callback
                                if (hoverCallback) 
                                    hoverCallback(pack)
                                this.setState({iHover: iPack})
                            }} onSelected={onSelected} {...this.props} />
                    )
                }
                </div>
            </div>
        )
    }

}

export default withStyles(styles)(SalePackages)